'use client';

import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useScrollBox from '@/components/hooks/useScrollBox';
import HeadingWithTools, { IHeadingWithToolsProps } from '@/components/ui/HeadingWithTools';
import styles from './styles.module.scss';

export interface ICarouselProps extends IHeadingWithToolsProps {
  children?: React.ReactNode;
  className?: string;
  layout?: 'default' | 'blog';
}

const Carousel: React.FunctionComponent<ICarouselProps> = (props) => {
  const { t } = useTranslation();
  const scrollWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const { isDragging } = useScrollBox(scrollWrapperRef);

  return (
    <div
      className={classNames(
        styles['carousel'],
        {
          [styles['layout-default']]: props.layout === 'default' || !props.layout,
          [styles['layout-blog']]: props.layout === 'blog',
        },
        props.className,
      )}
    >
      <HeadingWithTools
        pre={props.pre}
        title={props.title}
        description={props.description}
        level={props.level}
        buttonIcons={[
          {
            title: t('generic.actions.scrollLeft'),
            theme: 'primary',
            icon: 'arrow02-left',
            onClick: (e) => {
              e.preventDefault();
              const target = scrollWrapperRef.current;
              if (target) {
                const offset = parseInt(getComputedStyle(target).getPropertyValue('--scroll-offset'));
                target.scrollBy({
                  top: 0,
                  left: (target.getBoundingClientRect().width - offset) * -1,
                  behavior: 'smooth',
                });
              }
            },
            type: 'button',
          },
          {
            title: t('generic.actions.scrollRight'),
            theme: 'primary',
            icon: 'arrow02-right',
            onClick: (e) => {
              e.preventDefault();
              const target = scrollWrapperRef.current;
              if (target) {
                const offset = parseInt(getComputedStyle(target).getPropertyValue('--scroll-offset'));
                target.scrollBy({
                  top: 0,
                  left: target.getBoundingClientRect().width - offset,
                  behavior: 'smooth',
                });
              }
            },
            type: 'button',
          },
        ]}
      />
      <div className={classNames(styles['carousel__wrapper'], props.className)}>
        <div
          className={classNames(styles['carousel__scroller'], { [styles['dragging']]: isDragging })}
          ref={scrollWrapperRef}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

Carousel.displayName = 'Carousel';

export default Carousel;
