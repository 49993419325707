'use client';

import * as React from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import ButtonIcon from '@/components/ui/ButtonIcon';
import styles from './styles.module.scss';

export interface IListPeronsItemProps {
  id: string;
  imageUrl: string;
  name: string;
  position: string;
  contacts: {
    label: string;
    url: string;
  }[];
  description?: React.ReactNode;
}

export interface IListPersonsProps {
  items: IListPeronsItemProps[];
}

export const ListPersonsPerson: React.FunctionComponent<IListPeronsItemProps> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div
      className={classNames(styles['list-persons__item'], {
        [styles['is-expanded']]: expanded,
      })}
    >
      <div className={styles['list-persons__image']}>
        {props.description && (
          <ButtonIcon
            title="Info"
            icon="info-clean"
            onClick={(e) => {
              e.preventDefault();
              setExpanded(!expanded);
            }}
            theme="primary"
          />
        )}
        <Image
          src={props.imageUrl}
          alt={props.name}
          fill={true}
          priority={false}
        />
      </div>
      <div className={styles['list-persons__content']}>
        <div className={styles['list-persons__name']}>{props.name}</div>
        <div className={styles['list-persons__position']}>{props.position}</div>
        {props.contacts.length > 0 && (
          <ul className={styles['list-persons__contacts']}>
            {props.contacts.map((contact) => (
              <li key={contact.label}>{contact.url ? <a href={contact.url}>{contact.label}</a> : contact.label}</li>
            ))}
          </ul>
        )}
      </div>
      {props.description && (
        <div className={styles['list-persons__description']}>
          <div className={styles['list-persons__description__button']}>
            <ButtonIcon
              title="Info"
              icon="close"
              onClick={(e) => {
                e.preventDefault();
                setExpanded(!expanded);
              }}
              theme="primary"
            />
          </div>
          <div className={styles['list-persons__description__text']}>
            <p>{props.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const ListPersons: React.FunctionComponent<IListPersonsProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul className={styles['list-persons']}>
      {props.items.map((item) => (
        <li key={item.id}>
          <ListPersonsPerson {...item} />
        </li>
      ))}
    </ul>
  );
};

ListPersons.displayName = 'ListPersons';

export default ListPersons;
