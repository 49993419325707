'use client';

import * as React from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import Button from '@/components/ui/Button';
import Animations, { IAnimationsProps } from '@/components/ui/Animations';
import Block, { IBlockProps } from '@/components/ui/Block';
import Breadcrumb, { IBreadcrumbProps } from '@/components/ui/Breadcrumb';
import styles from './styles.module.scss';

export interface IPageHeaderProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  animation?: IAnimationsProps['animation'];
  theme?: IBlockProps['theme'];
  imageUrl?: string;
  logos?: string[];
  breadcrumb?: IBreadcrumbProps['items'];
}

const PageHeader: React.FunctionComponent<IPageHeaderProps> = (props) => {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <Block
      theme={props.theme}
      className={styles['page-header']}
    >
      <Breadcrumb items={props.breadcrumb} />
      <div className={styles['page-header__main']}>
        <h1>{props.title}</h1>
        {!!props.imageUrl && <div className={styles['page-header__text']}>{props.children}</div>}
        {props.animation && (
          <div className={styles['page-header__animation']}>
            <Animations animation={props.animation} />
          </div>
        )}
        {props.logos && props.logos.length > 0 ? (
          <ul className={styles['page-header__logos']}>
            {props.logos.map((logo) => (
              <li key={logo}>
                <Image
                  alt=""
                  src={logo}
                  fill={true}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className={styles['page-header__back']}>
            <Button
              title={t('generic.actions.goBack')}
              iconLeft="arrow03-left"
              onClick={(e) => {
                e.preventDefault();
                router.back();
              }}
              theme="link"
            />
          </div>
        )}
      </div>
      {props.imageUrl ? (
        <div className={styles['page-header__image']}>
          <Image
            src={props.imageUrl}
            alt=""
            fill={true}
            priority={true}
            sizes="50vw"
          />
        </div>
      ) : (
        <React.Fragment>{props.children && <div className={styles['page-header__text']}>{props.children}</div>}</React.Fragment>
      )}
    </Block>
  );
};

PageHeader.displayName = 'PageHeader';

export default PageHeader;
