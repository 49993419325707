'use client';

import * as React from 'react';
import Well from '@/components/ui/Well';
import LayoutSplit from '@/components/ui/LayoutSplit';
import Animations from '@/components/ui/Animations';
import styles from './styles.module.scss';

export interface IGuideProps {
  title: React.ReactNode;
  steps: {
    title: React.ReactNode;
    description?: React.ReactNode;
  }[];
}

const Guide: React.FunctionComponent<IGuideProps> = (props) => {
  return (
    <Well
      className={styles['guide']}
      theme="light"
    >
      <div className={styles['guide__title']}>
        <h2>{props.title}</h2>
        <Animations animation="animation09" />
      </div>
      <LayoutSplit className={styles['guide__steps']}>
        {props.steps.map((step, index) => (
          <div
            key={index}
            className={styles['guide__step']}
          >
            <div className={styles['guide__step__number']}>{index + 1}</div>
            <div className={styles['guide__step__title']}>{step.title}</div>
            {step.description && <div className={styles['guide__step__description']}>{step.description}</div>}
          </div>
        ))}
      </LayoutSplit>
    </Well>
  );
};

Guide.displayName = 'Guide';

export default Guide;
