'use client';

import * as React from 'react';
import classNames from 'classnames';
import { Modifier, usePopper } from 'react-popper';
import { Portal } from '@/components/ui/Portal';
import LoadingContent from '../LoadingContent';
import Icon from '../Icon';
import styles from './styles.module.scss';

export interface IControlCustomProps {
  /** Indicate value */
  hasValue: boolean;
  /** Inner fields */
  children: React.ReactNode;
  /** placeholder */
  placeholder: string;
  /** Disabled status */
  disabled?: boolean;
  /** Focus handler */
  onFocus?: React.DOMAttributes<HTMLDivElement>['onFocus'];
  /** Blur handler */
  onBlur?: React.DOMAttributes<HTMLDivElement>['onBlur'];
  /** Keyup handler */
  onClick?: React.InputHTMLAttributes<HTMLDivElement>['onClick'];
  /** Color higlight for errors */
  hasError?: boolean;
  /** className to append */
  className?: string;
  /** Loading */
  loading?: boolean;
  /** Layout to use */
  layout?: 'default' | 'mobile';
}

const sameWidth: Modifier<'sameWidth'> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.getBoundingClientRect().width}px`;
  },
};

/**
 * Custom field control
 */
const ControlCustom: React.FunctionComponent<IControlCustomProps> = (props) => {
  const [hasFocus, setHasFocus] = React.useState(false);
  const [dropOpen, setDropOpen] = React.useState(false);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const popper = usePopper(wrapperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      sameWidth,
    ],
  });

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target;
      if (dropOpen) {
        if (
          target &&
          wrapperRef.current &&
          !wrapperRef.current.contains(target as Node) &&
          popperElement &&
          !popperElement.contains(target as Node)
        ) {
          setDropOpen(false);
        }
      }
    };
    globalThis.addEventListener('click', handleClickOutside);
    return () => {
      globalThis.removeEventListener('click', handleClickOutside);
    };
  }, [dropOpen, popperElement, wrapperRef]);

  if (props.layout === 'mobile') {
    return (
      <div className={styles['control-custom-mobile']}>
        <div className={styles['control-custom-mobile__placeholder']}>{props.placeholder}</div>
        <div className={styles['control-custom__content']}>{props.children}</div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles['control-custom'],
          styles['size-default'],
          {
            [styles['has-error']]: props.hasError,
            [styles['disabled']]: props.disabled,
            [styles['focus']]: hasFocus,
          },
          props.className,
        )}
        onClick={(e) => {
          e.preventDefault();
          if (wrapperRef.current && !hasFocus) {
            wrapperRef.current.focus();
          }
        }}
        onFocus={(e) => {
          setHasFocus(true);
          if (typeof props.onFocus === 'function') {
            props.onFocus(e);
          }
        }}
        onBlur={(e) => {
          setHasFocus(false);
          if (typeof props.onBlur === 'function') {
            props.onBlur(e);
          }
        }}
        role="button"
        tabIndex={0}
        ref={wrapperRef}
      >
        <div
          className={styles['control-custom__holder']}
          onClick={() => {
            setDropOpen(!dropOpen);
          }}
        >
          <div className={styles['control-custom__placeholder']}>{props.placeholder}</div>
          {props.loading && (
            <span className={styles['control-custom__loader']}>
              <LoadingContent size="tiny" />
            </span>
          )}
          {props.hasValue && (
            <div className={styles['control-custom__count']}>
              <Icon
                width={20}
                height={20}
                kind="check"
              />
            </div>
          )}
          <Icon
            width={16}
            height={16}
            kind={dropOpen ? 'arrow01-up' : 'arrow01-down'}
            wrapperClassName={styles['control-custom__arrow']}
          />
        </div>
      </div>
      <Portal>
        <div
          className={classNames(styles['control-custom__drop'], {
            [styles['is-open']]: dropOpen,
          })}
          ref={setPopperElement}
          style={popper.styles.popper}
          {...popper.attributes.popper}
        >
          <div className={styles['control-custom__drop__inner']}>
            <div className={classNames(styles['control-custom__content'], styles['control-custom__drop__list'])}>{props.children}</div>
          </div>
        </div>
      </Portal>
    </React.Fragment>
  );
};

ControlCustom.displayName = 'ControlCustom';

export default ControlCustom;
