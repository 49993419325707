import * as React from 'react';
import { useRouter } from 'next/navigation';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import { Overlays } from '@/components/ui/Overlays';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';

const MobileFilter: React.FunctionComponent<{ children: React.ReactNode; buttons: React.ReactNode }> = (props) => {
  const router = useRouter();
  const { openOverlays } = React.useContext(Overlays);
  const { isOverlayOpen, zIndexOfOverlay } = useOverlay(OverlayKey.filter);
  const closeAllOverlays = () => {
    Array(openOverlays.length)
      .fill(0)
      .map(() => {
        router.back();
      });
  };
  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={closeAllOverlays}
      layout="default"
      zIndex={zIndexOfOverlay}
      hideClose={true}
    >
      <LayoutOverlay
        size="filter"
        title="Filtreeri"
        onClose={closeAllOverlays}
        titleTheme="lighter"
        footer={props.buttons}
      >
        <Block theme="lightest">{props.children}</Block>
      </LayoutOverlay>
    </Overlay>
  );
};

MobileFilter.displayName = 'MobileFilter';

export default MobileFilter;
